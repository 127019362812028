
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import router from "@/router";
import type { CertificateDataType } from "@/types/CertificateType";
import ApiService from "@/core/services/ApiService";
import { UserType, Certificate } from "@/types/UserType";
import { Actions } from "@/store/enums/StoreEnums";
import { MachineType } from "@/types/MachineType";


export default defineComponent({
  name: "course-catalog",
  components: {
  },
  setup() {
    const store = useStore();
    const user = computed<UserType>(() => store.getters.currentUser);
    const lastMachine = ref({} as MachineType);
    store.dispatch("getAllCourses");
    const courses = computed(() => store.getters.getCourseData);
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
    const certificatelength = ref(0);
    const calcProgress = ref(0);
    const certificatesResponse = ref({
      status: 0,
      data: []
    });
    const certificates = ref<CertificateDataType[]>([]);
    //const certificatelength = ref(0);
    const loading = ref(false);
    const { t } = useI18n();
    
    
    const userPublic = computed<UserType>(() => store.getters.publicUser);

    onMounted(async () => {
      loading.value = true
      // /api/lab/last
      ApiService.get('/labs/last').then((response) => {
        lastMachine.value = response.data.data;
      }).catch((error) => {
        lastMachine.value = {} as MachineType;
      });
      // certificatelength
      console.log(user.value.id)
      await store.dispatch(Actions.GET_PUBLIC_USER, { id: user.value?.id || 'me' });
      
      
      const calcProgress = computed(() => {
        return (userPublic.value.score['value'])
      });

      const certificatelength = computed(() => {
        //console.log(userPublic.value.certificates.length)
        return userPublic.value?.certificates?.length || 0;
      });

      loading.value = false
      
    })

    const userPatent = computed(() => {

      let patent = {
        name: '',
        icon: ''
      }

      if (user.value?.score) {
        const current_value = user.value?.score?.value;

        if (current_value < 2000) {
          patent.name = t(`${currentLanguage.value}.patents.recruit`);
          patent.icon = 'recruta.png';
        } else if (current_value >= 2000 && current_value < 4000) {
          patent.icon = 'soldado.png';
          patent.name = t(`${currentLanguage.value}.patents.soldier`);
        } else if (current_value >= 4000 && current_value < 6000) {
          patent.icon = 'cabo.png';
          patent.name = t(`${currentLanguage.value}.patents.cable`);
        } else if (current_value >= 6000 && current_value < 8000) {
          patent.icon = '3-sargento.png';
          patent.name = t(`${currentLanguage.value}.patents.3rd_sergeant`);
        } else if (current_value >= 8000 && current_value < 10000) {
          patent.icon = '2-sargento.png';
          patent.name = t(`${currentLanguage.value}.patents.2rd_sergeant`);
        } else if (current_value >= 10000 && current_value < 12000) {
          patent.icon = '1-sargento.png';
          patent.name = t(`${currentLanguage.value}.patents.1rd_sergeant`);
        } else if (current_value >= 12000 && current_value < 14000) {
          patent.icon = 'subtenente.png';
          patent.name = t(`${currentLanguage.value}.patents.warrant_officer`);
        } else if (current_value >= 14000 && current_value < 16000) {
          patent.icon = 'aspirante.png';
          patent.name = t(`${currentLanguage.value}.patents.aspirant`);
        } else if (current_value >= 16000 && current_value < 18000) {
          patent.icon = '2-tenente.png';
          patent.name = t(`${currentLanguage.value}.patents.2nd_lieutenant`);
        } else if (current_value >= 18000 && current_value < 20000) {
          patent.icon = '1-tenente.png';
          patent.name = t(`${currentLanguage.value}.patents.1nd_lieutenant`);
        } else if (current_value >= 20000 && current_value < 22000) {
          patent.icon = 'capitao.png';
          patent.name = t(`${currentLanguage.value}.patents.captain`);
        } else if (current_value >= 22000 && current_value < 24000) {
          patent.icon = 'major.png';
          patent.name = t(`${currentLanguage.value}.patents.major`);
        } else if (current_value >= 24000 && current_value < 26000) {
          patent.icon = 'tenente-coronel.png';
          patent.name = t(`${currentLanguage.value}.patents.lieutenant_colonel`);
        } else if (current_value >= 26000 && current_value < 28000) {
          patent.icon = 'coronel.png';
          patent.name = t(`${currentLanguage.value}.patents.colonel`);
        } else if (current_value >= 28000 && current_value < 30000) {
          patent.icon = 'general-de-ataque.png';
          patent.name = t(`${currentLanguage.value}.patents.attack_general`);
        } else if (current_value >= 30000 && current_value < 32000) {
          patent.icon = 'general-de-furtividade.png';
          patent.name = t(`${currentLanguage.value}.patents.stealth_general`);
        } else if (current_value >= 32000 && current_value < 34000) {
          patent.icon = 'general-de-inteligencia.png';
          patent.name = t(`${currentLanguage.value}.patents.general_of_intelligence`);
        } else if (current_value >= 34000) {
          patent.icon = 'marechal.png';
          patent.name = t(`${currentLanguage.value}.patents.marshal`);
        } else {
          patent.icon = 'recruta.png';
          patent.name = t(`${currentLanguage.value}.patents.recruit`);
        }
        return patent;
      } else {
        patent.name = t(`${currentLanguage.value}.patents.recruit`);
        patent.icon = 'recruta.png';
        return patent;
      }


    });

    const scoreLevel = (value: number | null) => {
      if (typeof value !== 'number' || !value) return { level: 0, xp: 0 };
      let level = Math.floor(value / 1000);
      let xp = value % 1000;

      return {
        level: level < 1 ? 0 : level,
        xp: xp
      };
    }

    return {
      user,
      courses,
      router,
      currentLanguage,
      certificatelength,
      loading,
      userPatent,
      userPublic,
      calcProgress,
      scoreLevel,
      lastMachine
    };
  },
  methods: {
    openCourse(id) {
      this.$router.push({
        name: "training-course",
        params: {
          course_id: id
        }
      });
    }
  }
});
